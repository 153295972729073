import {
  useEffect,
  useRef,
  useState,
} from 'react'

// Component
import ProductSuite from '@/components/Banner/templates/ProductSuite'
import {
  Wrapper,
  InnerProductSuiteTabsWrapper,
  ProductSuiteWrapper,
} from './ProductSuiteTabs.styles'
import TabItem from './components/TabItem'

// Type
import { WrapperContentInterface } from '@/interfaces/wrapperContent'
import { BannerProps } from '@/interfaces/banner'

// Util
import { useClickOutsideElement } from '@/hooks/useClickOutsideElement'
import { PrimaryTitle } from '@/components/WrapperContentItems/components'

const ANIMATION_DURATION = 3000

const ProductSuiteTabs: React.FC<WrapperContentInterface<BannerProps>> = ({
  primaryTitle,
  itemList,
}) => {
  const allItems = itemList?.filter(Boolean).splice(0, 9)
  const allItemsLength = Number.isInteger(allItems?.length)
    ? allItems?.length
    : -1

  const [currentItemIndex, setCurrentItemIndex] = useState(allItemsLength > 0 ? 0 : -1)

  const tabsWrapperRef = useRef<HTMLDivElement>(null)
  const isHover = useRef<boolean>(false)
  const isClick = useRef<boolean>(false)
  const timer = useRef<any>()

  const doRotate = () => {
    timer.current = setInterval(() => {
      setCurrentItemIndex((prevItemIndex) => (prevItemIndex + 1) % allItems.length)
    }, ANIMATION_DURATION)
  }

  useClickOutsideElement(tabsWrapperRef, () => {
    if (!isClick.current) return

    isClick.current = false
    isHover.current = false
    doRotate()
  })

  useEffect(() => {
    if (currentItemIndex === -1 || allItems?.length === 1) return
    doRotate()

    return () => clearInterval(timer.current)
  }, [])

  const onTabItemClick = (itemIndex: number): void => {
    if (itemIndex === currentItemIndex) return
    setCurrentItemIndex(itemIndex)
    clearInterval(timer.current)
    isClick.current = true
  }

  const onMouseOver = () => {
    if (isHover.current) return
    isHover.current = true
    clearInterval(timer.current)
  }

  const onMouseLeave = () => {
    if (!isHover.current || isClick.current) return
    isHover.current = false
    doRotate()
  }

  const currentItem = allItems?.[currentItemIndex]

  return (
    <Wrapper>
      {primaryTitle && <PrimaryTitle>{primaryTitle}</PrimaryTitle>}
      <ProductSuiteWrapper alignment={allItems[0].alignment}>
        <InnerProductSuiteTabsWrapper
          ref={tabsWrapperRef}
          onMouseOver={onMouseOver}
          onMouseLeave={onMouseLeave}
        >
          {allItems.map((item, index) => {
            const isActivated = index === currentItemIndex
            return (
              <TabItem
                key={index}
                item={item}
                index={index}
                isActivated={isActivated}
                onClick={onTabItemClick}
              />
            )
          })}
        </InnerProductSuiteTabsWrapper>
        {currentItem && <ProductSuite {...currentItem} />}
      </ProductSuiteWrapper>
    </Wrapper>
  )
}

export default ProductSuiteTabs
