import {
  TabIcon,
  TabLabel,
  TabHeader,
  TabItemWrapper,
  TabContent,
  Description,
  CustomTextButton,
  ArrowForwardIcon,
} from './TabItem.styles'
import { BannerProps } from '@/interfaces/banner'
import { getPageTheme } from '@/utils/theme.utils'
import { AssetRenderer } from '@/components'

interface ITabItemProps {
  item: BannerProps
  index: number
  isActivated: boolean
  onClick: (index: number) => void
}

const TabItem: React.FC<ITabItemProps> = ({
  item,
  index,
  isActivated,
  onClick,
}) => {
  const {
    subCopy, // TODO: remove field after migration, this will be fallback for now
    subCopyLong,
    secondaryButtonText,
    secondaryButtonLink,
    secondaryButtonOpenInNewTab,
    secondaryButtonEventLabel,
    backgroundColor,
  } = item

  return (
    <TabItemWrapper
      $isActivated={isActivated}
      onClick={() => onClick(index)}
      backgroundColor={getPageTheme(backgroundColor)?.light}
    >
      <TabHeader>
        {item?.productHeader?.image?.url && (
          <AssetRenderer
            asset={item.productHeader.image}
            ImageComponent={TabIcon}
          />
        )}
        <TabLabel>
          {item?.productHeader?.title}
          {!isActivated && <ArrowForwardIcon />}
        </TabLabel>
      </TabHeader>
      <TabContent>
        <Description>{subCopyLong || subCopy}</Description>
        {secondaryButtonText && secondaryButtonLink && (
          <CustomTextButton
            href={secondaryButtonLink}
            openInNewTab={secondaryButtonOpenInNewTab}
            eventLabel={secondaryButtonEventLabel}
          >
            {secondaryButtonText}
          </CustomTextButton>
        )}
      </TabContent>
    </TabItemWrapper>
  )
}

export default TabItem
